@mixin resortSectionTitle() {
	position: relative;
	padding: 40px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0;

	span {
		position: relative;
		z-index: 1;
		padding: 8px;
		background-color: #f0f2f5;
		display: block;
	}

	&:before {
		content: "";
		position: absolute;
		height: 2px;
		margin-top: -1px;
		width: 100%;
		background-color: black;
		top: 50%;
		z-index: 0;
	}
}

@mixin transformDisplay() {
	transform: scale3d(0.8, 0.8, 0.8);
	opacity: .3;
	transition: .5s ease-in-out;

	&[data-active] {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
}

@mixin scrollStyle($color : #423072, $trackColor : transparent) {
	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: $trackColor;
	}

	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: transparent;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: $color;
	}
}

@mixin root() {
	flex-grow: 1;
	display: flex;
	max-width: 1400px;
	width: 100%;
	margin: 0 auto;
}


@mixin max($pixels) {
	@media (max-width: $pixels + 'px') {
		@content
	}
}


@mixin min($pixels) {
	@media (min-width: $pixels + 'px') {
		@content
	}
}

@mixin minH($pixels) {
	@media (min-height: $pixels + 'px') {
		@content
	}
}
@mixin maxH($pixels) {
	@media (max-height: $pixels + 'px') {
		@content
	}
}

@mixin minMax($min, $max) {
	@media (max-width: $max + 'px') and (min-width: $min + 'px') {
		@content
	}
}

@mixin popoverStyle($color: #30254C, $padding: 16px 10px 8px) {
	&:global(.ant-popover) {
		:global(.ant-popover-inner-content) {
			padding: $padding;
			color: #FFFFFF;
		}

		:global(.ant-popover-arrow) {
			border-color: $color;
		}
		:global(.ant-popover-arrow-content) {
			background-color: $color;
		}

		:global(.ant-popover-inner) {
			background-color: $color;
			border-radius: 8px;
		}
	}
}

