@import "@css/shared";

.root {
	@include root;
	max-width: 1280px;
}

.aside {
	margin-top: 90px;
}

.tag {
	margin-bottom: 8px;
}

.loginHeader {
	padding: 48px 32px;
	h1 {
		font-family: var(--font-lora);
		font-size: 24px;
		line-height: 1.8;
		text-align: center;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		width: 100%;
		padding-top: 26px;
		color: #FFFFFF;
	}

}

.loginButton {
	@extend .btnFilled;
	width: 200px;
	margin: 0 auto;
	flex-shrink: 0;
	line-height: 44px;
}

.saveSearchRowContainer {
	background: #30254C;
	border-radius: 6px;
	margin-bottom: 16px;
	padding: 25px 14px;
	color: #FFFFFF;
	max-width: 661px;

	@include max(991) {
		max-width: none;
	}
}

.saveSearchRowHeader {
	display: flex;
	padding-bottom: 44px;
	align-items: center;
}

.settingsHeader {
	h1 {
		font-family: var(--font-lora);
		margin-bottom: 40px;
		margin-top: 24px;
		font-weight: 500;
		font-size: 40px;
		color: #CC78FF;
	}

	span {
		color: #FFFFFF;
	}
}

.settingsTabs {

	:global(.ant-tabs-nav .ant-tabs-ink-bar) {
		background-color: #CC78FF;
		height: 4px;
		border-radius: 18px;
	}

	:global(.ant-tabs-tab) {
		font-weight: normal;
		font-size: 24px;
		line-height: 19px;
		letter-spacing: 0.0583333px;
		color: #CFCFCF;
		opacity: 1;
		transition: .3s;
		padding: 12px 0;
	}

	&:global(.ant-tabs-top .ant-tabs-nav::before) {
		content: none;
	}

	:global(.ant-tabs-tab-btn:focus) {
		color: #5f5f5f;
	}

	:global(.ant-tabs-tab.ant-tabs-tab-active) {
		opacity: 1;

		:global(.ant-tabs-tab-btn) {
			font-family: var(--font-poppins);
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 20px;
			letter-spacing: 0.47px;
			color: #FFFFFF;

		}
	}

	:global(.ant-tabs-nav-more) {
		font-size: 24px;
		color: #FFFFFF;
	}

	:global(.ant-tabs-tab:hover) {
		opacity: 1;
	}

	& > :global(.ant-tabs-nav) {
		margin-bottom: 28px;
	}
}

.saveSearchCell {
	background: #3B3060;
	border-radius: 8px;
	height: 100%;
	padding: 16px;
	text-align: center;
	width: 150px;
	flex-shrink: 0;
	font-size: 14px;
	line-height: 26px;
	color: #FFFFFF;
	margin: 5.5px 0;

	hr {
		width: 77px;
		border: none;
		border-top: 1px solid #9C00FC;
	}
}

.saveSearchCellTitle {
	color: #FFFFFF;
	font-size: 12px;
	line-height: 26px;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 5.5px 0;
}

.saveSearchRowTitle {
	margin-bottom: 0;
	cursor: pointer;
	font-size: 18px;
	line-height: 20px;
	letter-spacing: 0.47px;
	color: #FFFFFF;
}

.saveSearchEditNameBtn {
	margin-right: auto;
	margin-left: 10px;
	cursor: pointer;
	color: #FFFFFF;
}

.searchesSpin {
	margin-left: 45%;
	margin-top: 20px;
}

.saveSearchDeleteBtn {
	margin-right: 16px;
	color: #CC78FF;
	background-color: transparent;
	border: none;

	&:hover {
		background-color: #9E00FF;
	}
}

.saveSearchCubeView {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}


.saveSearchListValuesRoot {
	display: flex;
	margin-bottom: 21px;
	align-items: stretch;
}

.saveSearchListValuesTitle {
	font-size: 14px;
	line-height: 16px;
	color: #FFFFFF;
	width: 70px;
	border-right: 1px solid #D2D2D2;
	margin-right: 11px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
}

.saveSearchListValues {
	display: flex;
	flex-wrap: wrap;

	span, .showMore {
		display: block;
		padding: 5px 11px;
		background: #423072;
		border-radius: 6px;
		margin-right: 14px;
		margin-bottom: 6px;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: 0.0583333px;
		color: #FFFFFF;

		&.hidden {
			display: none;
		}
	}
}

.showMore {
	cursor: pointer;
}

.noSaveSearch {
	display: flex;
	flex-direction: column;
	font-size: 24px;
	line-height: 32px;
	text-align: center;
	letter-spacing: 0.0583333px;
	color: #CFCFCF;
	margin: auto;
	max-width: 100%;
	width: 425px;
	align-items: center;
}


.notificationsRoot {
	max-width: 510px;
	margin-bottom: 32px;

	@include max(991) {
		max-width: none;
	}

	.notificationsTitle {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.47px;
		color: #FFFFFF;
		margin-bottom: 5px;
	}

	.form {
		padding: 38px;
		background: #30254C;
		border-radius: 13px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@include max(991) {
			background: transparent;
			padding: 0;
		}
	}

	.formItem {
		width: calc(50% - 7px);
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-right: 0;
		background-color: #3B3060;
		border-radius: 8px;
		margin-bottom: 12px;
		padding: 16px 0;

		@include max(991) {
			padding: 12px 0;
			height: 54px;
			order: 1;
			margin-bottom: 0;
			justify-content: center;
			flex-direction: row;
			flex-wrap: nowrap;

			:global(.ant-form-item-control) {
				flex-grow: 0;
				margin-right: auto;
				flex-basis: auto;
			}
		}
		:global(.ant-form-item-label) {
			flex-grow: 0;
			flex-basis: auto;
			margin: auto 16px auto auto;
		}

		label {
			color: #FFFFFF;

			&:after {
				content: none;
			}
		}
	}

	.fullWidth {
		width: 100%;
		margin-right: 0;

		@include max(991) {
			order: 0;
			margin-bottom: 12px;
		}
	}

	.select {
		width: 100%;

		&:not(.ant-select-disabled) {
			:global(.ant-select-selector) {
				border: none;
				box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.15);
				background-color: #3B3060;
				height: 52px;

				:global(.ant-select-selection-placeholder) {
					font-size: 14px;
					line-height: 52px;
					height: 52px;
					padding: 0 16px;
				}
			}
		}

		:global(.ant-select-arrow) {
			color: #FFFFFF;
			opacity: .5; // disabled
		}
	}
}

.personalForm {
	max-width: 450px;
	width: 100%;
	padding-left: 8px;

	:global(.ant-form-item-label) {
		padding-bottom: 0;

		label {
			font-size: 14px;
			line-height: 21px;
			color: #FFFFFF;
		}
	}

	:global(.ant-input), :global(.ant-select .ant-select-selector), :global(.ant-picker), :global(.ant-input-affix-wrapper > .ant-input:not(textarea)) {
		width: 100%;
		background-color: #3B3060;
		height: 52px;
		box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.15);
		border-radius: 8px;
		padding: 16px 14px;
		color: #FFFFFF;
		border-color: transparent;

		&:focus {
			border: 1px solid #c278f8;
		}
	}

	:global(.ant-input-affix-wrapper), :global(.ant-input-affix-wrapper:focus),:global(.ant-input-affix-wrapper:hover) {
		background-color: #423072!important;
		border-color: transparent;
		padding: 0;
		box-shadow: none;
		border-radius: 8px;

		:global(.anticon) {
			color: #fff;
		}

	}

	:global(.ant-input-disabled) {
		color: #7f7f7f;
		font-style: italic;
	}

	:global(.ant-form-item-has-error) {
		:global(.ant-input, .ant-select .ant-select-selector) {
			background-color: #3B3060;
			color: #FFFFFF;
		}
		:global(.ant-input-status-error.ant-input), :global(.ant-input-status-error.ant-input:hover), :global(.ant-input-status-error.ant-input:focus) {
			background-color: #3B3060;
			color: #FFFFFF;
			border-color: rgb(194, 120, 248);
		}
	}


	:global(.ant-select) {
		:global( .ant-select-selector) {
			border: none;
		}

		:global(.ant-select-arrow) {
			color: #FFFFFF;
		}
	}

	:global(.ant-select-single.ant-select-show-arrow .ant-select-selection-item) {
		line-height: 18px;
	}
	:global(.ant-picker-suffix) {
		color: #FFFFFF;
	}

	:global(.ant-picker-input  input) {
		color: #FFFFFF;
	}

	:global(.ant-form-item.last) {
		margin-bottom: 0;
	}

	.submitBtn {
		height: 42px;
		background: #9E00FF;
		border-radius: 8px;
		border: none;
		transition: background-color .3s;
		color: #FFFFFF;
		line-height: 42px;
		padding: 0 16px;
		text-align: center;
		letter-spacing: 0.175px;
		font-size: 18px;
		cursor: pointer;

		&:hover, &:focus {
			color: #FFFFFF;
			background-color: #CC78FF;
		}
	}

	.resetBtn, .deleteAccount {
		border: 2px solid #CC78FF;
		box-sizing: border-box;
		filter: drop-shadow(0px 15px 41px rgba(0, 0, 0, 0.18));
		border-radius: 8px;
		background-color: transparent;
		font-size: 18px;
		line-height: 38px;
		color: #FFFFFF;
		cursor: pointer;
		transition: background-color .3s;
		padding: 0 16px;
		margin-right: 16px;
		text-align: center;
		letter-spacing: 0.175px;

		&:hover {
			background-color: rgba(204, 120, 255, 0.2);
		}
	}
	.deleteAccount {
		border: 2px solid #FE5A9F;
		float: right;
		margin-right: 0;
		background-color: #FE5A9F;
	}
	@include max(500) {
		.resetBtn, .submitBtn {
			width: calc(50% - 8px);
			margin-bottom: 16px;
		}
	}
}
