.btnTransparent {
	border: 2px solid #CC78FF;
	box-sizing: border-box;
	//filter: drop-shadow(0px 15px 41px rgba(0, 0, 0, 0.18));
	border-radius: 8px;
	background-color: transparent;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: 0.5px;
	color: #FFFFFF;
	padding: 18px 50px;
	cursor: pointer;
	transition: background-color .3s;

	&:hover, &:focus, &:active {
		background-color: rgba(204, 120, 255, 0.2);
		color: #FFFFFF;
	}

	@include max($mobileSwitch) {
		padding: 7px 50px;
		font-size: 14px;
	}
}

.btnFilled {
	background-color: #9E00FF;
	border-radius: 8px;
	border: none;
	font-weight: 500;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: 0.5px;
	color: #FFFFFF;
	transition: background-color .3s;
	cursor: pointer;
	height: auto;

	&:hover, &:focus {
		color: #FFFFFF;
		background-color: #8c00e0;
	}
}

.alCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}


.confirmModal {
	:global(.ant-modal-content) {
		background-color: #423072;
		border-radius: 8px;
	}

	:global(.ant-modal-confirm-title) {
		color: #FFFFFF;
	}

	&:global(.ant-modal-confirm-warning .ant-modal-confirm-body .anticon), &:global(.ant-modal-confirm-confirm .ant-modal-confirm-body .anticon) {
		color: #B07AD1;
	}

	:global(.ant-btn) {
		border-radius: 8px;
		border: 2px solid #CC78FF;
		background-color: transparent;
		color: #FFFFFF;

		&:hover, &:focus {
			color: #FFFFFF;
		}

		&:global(.ant-btn-primary) {
			background: #9E00FF;
			border-color: transparent;

			&:hover, &:focus {
				color: #FFFFFF;
			}
		}
	}
}


.menu {
	background-color: #2B2741;
	position: relative;
	z-index: 2;
	border-radius: 8px;

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: #2B2741;
	}

	&::-webkit-scrollbar {
		width: 8px;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #423072;
	}

	:global(.ant-dropdown-menu-item) {
		color: #FFFFFF;
		background-color: transparent;

		&:global(.ant-dropdown-menu-item-active) {
			background-color: #3B305F;
		}

		&:global(.ant-dropdown-menu-item-selected) {
			color: #c278f8;
		}
	}
}

.subscribeSection {
	padding-bottom: 60px;

	h3 {
		font-size: 24px;
		line-height: 48px;
		text-align: center;
		letter-spacing: 0.41px;
		color: #FFFFFF;
	}

	h4 {
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: #CFCFCF;
		margin-bottom: 30px;
	}

	.form {
		display: flex;
		justify-content: center;
		background-color: #3B3060;
		box-shadow: inset 0 3px 4px rgba(0, 0, 0, 0.15);
		border-radius: 8px;
		max-width: 710px;
		margin: auto;

		label {
			flex-grow: 1;
			position: relative;
			margin-right: 2px;

			svg {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 32px;
			}
		}

		input {
			background-color: transparent;
			border: none;
			height: 48px;
			width: 100%;
			margin-top: 2px;
			padding-left: 73px;
			padding-right: 30px;
			border-radius: 8px 0 0 8px;
			outline: none;
			color: #FFFFFF;
			font-size: 16px;
			line-height: 24px;
			transition: .3s;

			&:focus {
				box-shadow: 0 0 0 2px #CC78FF;
			}

			&::placeholder {
				letter-spacing: 0.47px;
				color: #e5e5e5;
			}

			&:-webkit-autofill {
				box-shadow: inset  0 0 30px 30px #3B3060, 0 0 0 2px #CC78FF;
				-webkit-text-fill-color: #ffffff !important;
				transition: background-color 500000s, all .3s;
			}
		}

		button {
			height: 52px;
			width: 270px;
			position: relative;
			z-index: 1;
			border-radius: 0 8px 8px 0;

			&:disabled {
				background-color: rgb(59 48 96);
				border: 2px solid rgba(204, 120, 255, 0.73);
				color: rgba(255, 255, 255, 0.54);
			}
		}
	}
	.thank {
		text-align: center;
		font-size: 33px;
	}

	@include max($mobileSwitch) {
		padding-left: 20px;
		padding-right: 20px;
		max-width: 100vw;
		margin: auto;

		h3 {
			font-size: 14px;
			line-height: 14px;
		}
		h4 {
			line-height: 14px;
			font-size: 12px;
		}

		.form {
			padding-right: 0;

			label {
				svg {
					left: 27px;
				}
			}

			input {
				font-size: 12px;
				line-height: 24px;
				padding-left: 65px;
			}

			button {
				width: 52px;
				padding-left: 0;
				padding-right: 0;
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;

				svg {
					transform: rotate(-90deg);
					height: 23px;
					margin-top: 8px;
				}
			}
		}
	}
}
